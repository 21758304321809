import $ from 'jquery'
import modal from './modal'
import { throttle } from './throttle'

export default class Top {
  constructor() { }

  initialize() {
    this.$el = $('.js-top')
    if(this.$el.length <= 0) return
    this.registShowInitialModal()
    this.$banner = $('.js-banner')
    this.$navBtn = $('.js-nav-btn')
    this.$bannerHiddenTarget = $('.js-banner-hidden-target')
    const onScroll = throttle((e) => this.onScroll(e), 50)
    window.addEventListener('scroll', onScroll)
    this.forsePopup()
    this.initMemberToggle()
    onScroll()
  }

  registShowInitialModal() {
    const modalTag = window.TOP_YOUTUBE_ENBED_TAG
    if(!modalTag || modalTag.length <= 0) return
    modal.show(`<div class="youtube">${modalTag}</div>`)
  }

  forsePopup() {
    const modalContent = document.querySelector('.js-forse-popup')
    var shown = localStorage.getItem("forsePopupShown");
    if(shown) return
    if(modalContent) {
      setTimeout(() => {
        localStorage.setItem('forsePopupShown', true);
        modal.show($(modalContent).html())
      }, 8000)
    }
  }

  initMemberToggle() {
    const $toggleBtns = $('.js-member-toggle')
    console.log($toggleBtns)
    $toggleBtns.on('click', (e) => {
      const $target = $(e.currentTarget)
      const targetId = e.currentTarget.dataset.toggleTarget
      $target.toggleClass('open')
      const $detail = $(`.js-member-detail[data-toggle-id="${targetId}"]`)
      $detail.slideToggle()
    })
  }
  onScroll() {
    const scrollY = window.scrollY
    const windowHeight = window.innerHeight
    const top = this.$bannerHiddenTarget.get()[0].getBoundingClientRect().top

    if(windowHeight / 2 > top) {
      this.$banner.addClass('top-Banner-hidden')
      // this.$navBtn.removeClass('pc-hidden')
    } else {
      this.$banner.removeClass('top-Banner-hidden')
      // this.$navBtn.addClass('pc-hidden')
    }
  }
}
